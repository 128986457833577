@import '../../../../assets/scss/screen.scss';

.two-map-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .first-sec, .second-sec {
    width: calc(50% - 36px) !important;
    .mapRow {
      justify-content: center;
      display: flex;
    }
  }
  .card-div {
    width: 100%;
    height: 100%;
  }
  .map-container {
    &.chart-mixed-ground-level-along-road {
      max-height: 40vh !important;
    }

    @include tablet {
      flex-direction: column;
      width: 40vw;
    }
  }
  @include tablet {
    flex-direction: column;
    .first-sec, .second-sec {
      width: 100% !important;
    }
    .card-div {
      margin-bottom: 10px;
    }
  }
}