.project-card {
    min-width: 15em; 
  }
  .no-border-radius {
    border-radius: 0;
  }

  .card-title{
    font-family: MediumSizeFont;
  }
  
  .card-text{
    font-family: BookSizeFont;
  }

  thead{
    font-family: MediumSizeFont;
    background-color: white;
    color:black;
  }

  tbody{
    font-family: BookSizeFont;
    background-color: white;
    color:black;
  }