canvas {
  width: 100% !important;
  height: 100% !important;
}

/* .chartjs-render-monitor {
  width: 40vw;
  height: 45vh;
  background-color: rgba(0, 4, 5, 0.8) !important;
  color: white !important;
} */
