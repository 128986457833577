@import '../../../assets/scss/colors.scss';

.hero {
  color: $green1;
  text-align: center;
  padding-bottom: 3em;
}

/* .hero h1 {
  font-weight: 600;
  font-size: 26pt;
  padding: 1em;
  padding-top: 2em;
  
  
} */

#sign-in-button {
  color: #f8f9fa;
  background: #0def61;
  border: 0px solid #269CE9;
  border-radius: 50px;
  font-size: 18px;
  padding: 18px 84px;
  /* margin-block: 25vh */
}

#other-button {
  color: #1d1d1b;
  background: $white1;
  border: 0px solid #269CE9;
  border-radius: 30px;
  font-size: 16px;
  padding: 8px 54px;
}

#app {
  background: url("./../../../assets/waywize_img2.jpg") no-repeat;
  background-size: cover;

  .welcome-h1 {
    margin-top: 25vh;
    color: $white1;
    position: relative;
  }

  .mode-value {
    font-size: 15px;
    position: absolute;
    top: 0;
    font-family: 'MediumSizeFont';
  }
}

.login-logo {
  margin-top: 20px;
  width: 200px;
  height: 40px;
  opacity: 1;
}