.mapRow {
  text-align: center;
  height: 100%;
  width: 100%;
}

.map-container {
  /* height: 100vh;
    width: 100%; */
  /* width: 40vw !important; */
  height: 45vh !important;
}
