/* canvas{

    width:1500px !important;
    height:600px !important;
  
  } */

.chartjs-render-monitor {
  width: 40vw;
  height: 35vh;
  background-color: white !important;
  color: white !important;
}

@font-face {
  font-family: MediumSizeFont;
  src: url(../../../assets/fonts/Rene\ Bieder\ -\ Campton\ Medium.otf);
}

@font-face {
  font-family: BookSizeFont;
  src: url(../../../assets/fonts/Rene\ Bieder\ -\ Campton\ Book.otf);
}
