 /* canvas{
    filter: brightness(60%) grayscale(50%) opacity(50%);
} */

.ol-overlaycontainer{
    position: relative !important;
}

#popup-content{
    background-color: rgba(0, 0, 0, 0.336) !important;
    color:white;
}

#split-pointer {
    background-color: #007bff;
    color: #fff;   
}