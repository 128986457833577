@import '../../../assets/scss/colors.scss';

.waywize-table-pagination {
    padding: 10px 0;
    margin: 0 auto;
    button {
        height: 40px;
        width: 40px;
        background-color: $green1;
        outline: none;
        border-radius: 4px;
        box-shadow: none;
        border: none;
        color: $white1;
        margin: 0 5px;
        &.current-page {
            border: solid 1px $blue3;
        }
        &:disabled {
            cursor: not-allowed;
            background-color: $grey2;
            border: none;
        }
    }
    span {
        margin: 0 5px;
    }
    select {
        height: 30px;
    }
}