.landxml-select-input-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    label {
        margin: 0px;
    }

    select {
        height: 56px;
        padding: 0 20px;
        border: solid 1px;
        border-radius: 4px;
        outline: none;
    }
}
