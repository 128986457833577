@import "../../../../assets/scss/colors.scss";

#btn-popup,
.btn-edit-segment {
  background: $blue3;
  border: 1px solid $blue3;
}
#btn-segment,
.btn-segment,
.btn-segment-save {
  background: $green1;
  border: 1px solid $green1;
}

.btn-delete-segment {
  background: $red1;
  border: 1px solid $red1;
}

#btn-popup,
#btn-segment,
.btn-segment,
.btn-segment-save,
.btn-edit-segment,
.btn-delete-segment {
  color: $white1;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  border-radius: 16px;
}
