.mapRow {
  text-align: center;
  height: 100%;
  width: 100%;
}

.map-container {
  /* height: 100vh;
    width: 100%; */
  width: 40vw !important; 
  height: 60vh !important;
}

div {
  font-family: MediumSizeFont;
}
thead {
  font-family: MediumSizeFont;
}

tbody {
  font-family: BookSizeFont;
}

.according-size {
  width: 50vw;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.maps-table-body td {
  border-top-style: hidden !important;
}
