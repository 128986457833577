@use "./index" as *;

:export {
    // Define Colors
    black1: $black1;
    richBlack: $richBlack;
    teal: $teal;
    white1: $white1;
    white2: $white2;
    blue1: $blue1;
    blue2: $blue2;
    blue3: $blue3;
    blue4: $blue4;
    blue5: $blue5;
    blue6: $blue6;
    green1: $green1;
    green2: $green2;
    green3: $green3;
    green4: $green4;
    green5: $green5;
    lightGreen: $lightGreen;
    lightCyan: $lightCyan;
    red1: $red1;
    red2: $red2;
    grey1: $grey1;
    grey2: $grey2;
    grey3: $grey3;
    grey4: $grey4;
    yellow1: $yellow1;
    aqua: $aqua;
    waterSpout: $waterSpout;

    // Define Screen Sizes
    mobile: $tablet-width-min;
    tablet: $desktop-width-min;
}

$sides: (top, bottom, left, right);

@for $i from 0 through 99 {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$i} {
            margin-#{$side}: #{$i}px;
        }
        .p#{str-slice($side, 0, 1)}-#{$i} {
            padding-#{$side}: #{$i}px;
        }
    }
}

.d-flex {
    display: flex;
}

.ai-center {
    align-items: center;
}

.center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.between-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.evenly-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.around-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.f-end-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.f-column {
    flex-direction: column;
}

.f-right {
    float: right;
}

.f-left {
    float: left;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.round-btn {
    font-size: 20px;
    padding: 5px;
    text-decoration: none;
    border-radius: 50%;
    border: none;
    margin-left: 5px;
    outline: none;
}

.edit-round-btn {
    @extend .round-btn;
    background: $blue2;
    color: $blue3;
}

.delete-round-btn {
    @extend .round-btn;
    background: $red2;
    color: $red1;
}

button:focus {
    outline: none !important;
}

button:disabled {
    cursor: not-allowed;
    background-color: $grey2 !important;
    border: none !important;
    &:hover {
        background-color: $grey2 !important;
        border: none !important;
    }
}

input::placeholder {
    font-family: "MediumSizeFont";
}
input::-webkit-input-placeholder {
    font-family: "MediumSizeFont";
}
input::-moz-placeholder {
    font-family: "MediumSizeFont";
}
input:-ms-input-placeholder {
    font-family: "MediumSizeFont";
}
input:-moz-placeholder {
    font-family: "MediumSizeFont";
}

.black-n-white {
    background-color: $white1 !important;
    color: $black1;
}

.table {
    font-size: 15px !important;
    background-color: rgba(0, 0, 0, 0.336) !important;
    color: $white1 !important;
    .t-body-v-middle {
        td {
            vertical-align: middle;
        }
    }
}

// HINT: Style required after react-bootstrap library - Started
.table td, .table th {
    padding: 0.75rem !important;
    vertical-align: top;
    border-top:1px solid #dee2e6;
   }

.table>:not(caption)>*>* {
    background-color: unset !important;
    border-bottom-width: 0px !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: unset !important;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0,0,0,.075);  
}
.table-dark {
    --bs-table-bg: unset !important;
}
.form-group-margin {
    margin-bottom: 1rem;
}
// HINT: Style required after react-bootstrap library - Ended