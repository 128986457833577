@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.spin-loader-container {
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1051;

    .waywize-progress-container {
        padding: 40px;
        max-width: 650px;
        border-radius: 20px;
        background-color: $blue4;
        .progress-bar-text {
            font-size: 16px;
        }

        #waywize-progress-holder {
            width: 100%;
            background-color: $grey3;
            border-radius: 5px;
        }

        #waywize-progress-bar {
            max-width: 1%;
            height: 26px;
            font-size: 17px;
            background-color: $green4;
            text-align: center;
            line-height: 28px;
            color: $white1;
            border-radius: 5px;
            @include transitionVal(max-width 0.5s ease-out);
        }
    }
}
