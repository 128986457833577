@import "../../../../assets/scss/colors.scss";

.project-container {
    a.btn-project {
        display: flex;
        width: 82px;
        text-decoration: none;
        border-radius: 5px;
        &:hover {
            color: $white1;
        }
    }
    .project-info-sec {
        margin: 20px 0;
        padding: 20px 0;
        text-align: justify;
        color: $black1;
        border-top: solid 1px $black1;
        .project-edit-cont {
            width: 440px;
            .project-btn-n-input {
                display: flex;
                flex-direction: column;
            }

            button {
                max-width: 200px;
            }
        }
        input {
            padding: 6px 12px;
            box-shadow: none;
            outline: none;
            border: solid 1px $black1;
        }
        span {
            color: $green1;
        }
    }
    .btn-project {
        border-radius: 5px !important;
        border: none;
    }
}
