/* canvas{

    width:1500px !important;
    height:600px !important;
  
  } */

.chartjs-render-monitor {
  width: 41vw;
  height: 55vh;
  background-color: rgba(0, 4, 5, 0.8) !important;
  color: white !important;
}

canvas {
  background-color: white !important;
}
