@import './assets/scss/common.module.scss';
@import './assets/scss/colors.scss';

.App {
  text-align: center;
  background-color: $black1;
  background-image: url("assets/waywize_img2.jpg");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white1;
  background-attachment: fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .app-spin-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: MediumSizeFont;
  src: url('./assets/fonts/Rene\ Bieder\ -\ Campton\ Medium.otf');
}

@font-face {
  font-family: BookSizeFont;
  src: url('./assets/fonts/Rene\ Bieder\ -\ Campton\ Book.otf');
}

/* Paras */
.site-layout .site-layout-background {
  background: $white1;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

#header-nav {
  padding-left: 0px;
  padding-right: 0px;
  .f-left {
    .dropdown-menu.show {
      max-height: 200px;
      overflow-y: scroll;
    }
  }
}
.container {
  box-shadow: 0px 9px 13px $grey4;
  border-radius: 10px;
}

.no-shadow {
  box-shadow: none;
}