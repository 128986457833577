@import '../../../../assets/scss/colors.scss';

.land-xml-importer-container {
    .landxml-select-input-container,
    .landxml-number-input-container {
        max-width: 350px;
        min-width: 350px;
        width: 100%;
        .err-msg {
            font-size: 14px;
            margin: 0px;
        }
    }
    .land-xml-section-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
    }
    .alignment-selector {
        width: 100%;
        margin-top: 5px;
        border-top: solid 1px $grey2;
        .landxml-select-input-container {
            max-width: 100%;
        }
    }
    .checkbox-sec {
        input, label {
            cursor: pointer;
        }
    }
}
