@import '../../../../assets/scss/colors.scss';

.alignment-tab-container {
    margin: 10px auto 30px;

    .alignment-tab-btn {
        height: 56px;
        width: 100%;
        background-color: $green1;
        color: $white1;
        border-radius: 5px;
        cursor: pointer;
        &.is-active {
            background-color: $green2;
        }
    }
}
