@import "../../../assets/scss/colors.scss";

.black-n-white {
  .no-altr-msg {
    display: flex;
    align-items: center;
    color: $black1;
  }
}

.fileinput {
  color: #1d1d1b !important;
  background: $white1 !important;
  border: 0px solid #269ce9 !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  padding: 5px 5px !important;
}

.fontsizes {
  font-size: 20px;
}

li {
  font-size: 15px;
}

form {
  font-size: 15px;
  .ref-reg-input {
    position: absolute;
    right: 0;
  }
}

div {
  font-family: MediumSizeFont;
  font-size: 20px;
}

li {
  font-family: BookSizeFont;
}

.copy-traffic {
  background-color: $blue1 !important;
  border: 1px solid $blue1 !important;
}

#btn {
  background: $green1;
  color: $white1;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  border-radius: 16px;
  border: 1px solid $green1;
}

#btn-delete {
  background: #f21505;
  color: $white1;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  border-radius: 16px;
  border: 1px solid #f21505;
}

#btn-delete:hover {
  background: #690902;
  background-image: -webkit-linear-gradient(top, #690902, #690902);
  background-image: -moz-linear-gradient(top, #690902, #690902);
  background-image: -ms-linear-gradient(top, #690902, #690902);
  background-image: -o-linear-gradient(top, #690902, #690902);
  background-image: linear-gradient(to bottom, #690902, #690902);
  text-decoration: none;
}

#btn:hover:enabled {
  background: $green2;
  background-image: -webkit-linear-gradient(top, $green2, $green2);
  background-image: -moz-linear-gradient(top, $green2, $green2);
  background-image: -ms-linear-gradient(top, $green2, $green2);
  background-image: -o-linear-gradient(top, $green2, $green2);
  background-image: linear-gradient(to bottom, $green2, $green2);
  text-decoration: none;
}

.table-body td {
  border-right-style: hidden !important;
}

.stats-bar-holder {
  width: 92px;
  display: grid;
  row-gap: 7px;
  min-width: 92px;
  max-width: 92px;
  height: 100%;
  margin: 9px auto;
  flex-direction: column;

  .energy-bar,
  .cost-bar,
  .co2-bar {
    height: 7px;
    border-radius: 6px;
  }

  .energy-bar {
    background: $lightCyan;
  }

  .cost-bar {
    background: $blue1;
  }

  .co2-bar {
    background: $green1;
  }
}

.landxml-file-upload-btn-container {
  input[type="file"] {
    display: none;
  }

  .landxml-file-upload-btn {
    border: 1px solid #ccc;
    cursor: pointer;
    width: 100%;
    height: 56px;
    color: $white1;
    background-color: $blue1;
  }
}

.xml-uploader-btn {
  border-radius: 4px !important;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.err-msg {
  color: $red1;
}