@import "../../../assets/scss/colors.scss";
@import '../../../assets/scss/dynamic.scss';

.waywize-select-input-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    label {
        margin: 0px;
    }
    select {
        height: 40px;
        width: 370px;
        padding: 0 20px;
        border-radius: 4px;
        outline: none;
        border: 2px solid $grey2;
        &:focus,
        &:active {
            @include boxShadow(0px 3px 9px #828dcbb3);
            border: 2px solid $blue3;
        }
    }
}
