.dropbtn {
  background: #172679;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* to disable clicking */
#filterbutton {
  pointer-events: none;
  background: #172679;
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* display will control the apparnace of the window */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover #filterbutton {
  background-color: #9eabed;
}
