@import "../../../../assets/scss/colors.scss";

.energy-circle::before,
.cost-circle::before,
.co2-circle::before {
    content: " ";
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.energy-circle::before {
    background: $lightCyan;
}

.cost-circle::before {
    background: $blue1;
}

.co2-circle::before {
    background: $green1;
}
