@import "../../../assets/scss/colors.scss";

.auth-popup-container {
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgb(78 78 78 / 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    .auth-popup-message {
        height: 200px;
        max-width: 650px;
        border-radius: 5px;
        padding: 20px;
        background-color: $white1;
        color: $black1;
        .reload-act-btn {
            margin: 0 5px;
            cursor: pointer;
            &:hover {
                color: $green1;
            }
        }
    }
}
