$black1: #000000;
$richBlack: #004040;
$teal: #008080;
$white1: #ffffff;
$white2: #fbfbff;
$blue1: #8087eb;
$blue2: #F1F3FF;
$blue3: #172678;
$blue4: #2438a8;
$blue5: #2e4af2;
$blue6: #9eabed;
$green1: #05f29c;
$green2: #03784f;
$green3: #69F7C2;
$green4: #05b573;
$green5: #9cfad6;
$lightGreen: #05F2C6;
$lightCyan: #66ffff;
$red1: #FF313A;
$red2: #FFEBEC;
$grey1: #C6C6CE3B;
$grey2: #A7ACBF;
$grey3: #5a5a5a;
$grey4: #c6c6ce33;
$yellow1: #ffff00;
$aqua: #00FFFF;
$waterSpout: #96ffff;