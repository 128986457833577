@mixin boxShadow($val) {
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  -ms-box-shadow: $val;
  -o-box-shadow: $val;
  box-shadow: $val;
}

@mixin transitionVal($val) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -ms-transition: $val;
  -o-transition: $val;
  transition: $val;
}

@mixin transition2Val($val1, $val2) {
  -webkit-transition: $val1, $val2;
  -moz-transition: $val1, $val2;
  -ms-transition: $val1, $val2;
  -o-transition: $val1, $val2;
  transition: $val1, $val2;
}

@mixin transition3Val($val1, $val2, $val3) {
  -webkit-transition: $val1, $val2, $val3;
  -moz-transition: $val1, $val2, $val3;
  -ms-transition: $val1, $val2, $val3;
  -o-transition: $val1, $val2, $val3;
  transition: $val1, $val2, $val3;
}

@mixin inputPlaceholder($val) {
  input::placeholder {
    color: $val;
  }
  input::-webkit-input-placeholder {
    color: $val;
  }
  input::-moz-placeholder {
    color: $val;
  }
  input:-ms-input-placeholder {
    color: $val;
  }
  input:-moz-placeholder {
    color: $val;
  }
}
