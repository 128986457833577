@import '../../../assets/scss/colors.scss';

.card-body {
  /* background-color: #2338b5;
    color: $white1; */
  background-color: $white1;
  color: $black1;
  font-weight: 150;
  font-size: 20px;
  border-radius: 25px;
  padding: 20px;
}

tr {
  background-color: $white1;
  color: $black1;
}

.btn-project {
  background: $lightGreen;
  color: $white1;
  font-size: 20px;
  padding: 12px 15px;
  text-decoration: none;
  border-radius: 16px;
  border: 1px solid $lightGreen;
  &.round-btn {
    border-radius: 50%;
    border: none;
  }
  &.simple-round-radius {
    border-radius: 5px;
  }
}

hr.space-1 {
  background: transparent;
  color: transparent;
  margin: 0;
  border-style: none;
  height: 0.5vw;
}

.add_project_svg
{
  margin-right: 10px;
  margin-bottom: 5px;
}