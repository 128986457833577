@import '../../../assets/scss/colors.scss';

.terms-accepted-container {
    max-width: 70%;
    border-radius: 5px;
    margin: 20px auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    color: $black1;
    padding: 50px;
    background-color: $grey1;
    box-shadow: 0px 9px 13px $grey4;
    .terms-accepted-content {
        text-align: left;
        max-width: 1100px;
        width: 100%;
    }
    button {
        background-color: $green1;
        border-radius: 5px;
        min-width: 100px;
        color: $white1;
        border: none;
        outline: none;
        height: 56px;
        padding: 12px 20px;
    }
}