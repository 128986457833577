@import "../../../assets/scss/colors.scss";

.exp-msg-container {
    min-height: 50px;
    color: $white1;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 34px;
    margin-left: 34px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: $blue1;
    transition: ease-in-out 0.5s;
    &.hide-msg {
        min-height: 0px;
        p, span {
            display: none;
        }
    }
    p {
        margin: 0;
        text-align: left;
    }
    span {
        cursor: pointer;
    }
}
