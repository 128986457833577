.project-card {
    min-width: 15em; 
  }
  .no-border-radius {
    border-radius: 0;
  }

div{
    font-family: MediumSizeFont;
}

li{
    font-family: BookSizeFont;
}

.tab-body {
  
  
}


/* Active tab */
.tab-body ul > li.active > a,
.tab-body ul > li.active > a:hover,
.tab-body ul > li.active > a:focus {
    background-color: blue;
    color: grey;
}

.tab-body .nav-link.active{
  color:white !important;
  background-color: #172679 !important;
}

.tab-body .nav-link{
  color:black !important;
}



