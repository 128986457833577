/* .logo{
    width:100px;
}

.globe{
    width: 20px;
    height: 20px;
    fill: #009df0; 
}

.multiselect{
    border-width:0px !important;
    background-color: #f8f9fa !important;
    min-width:210px;
    max-width:500px;
    margin-right: 3px;
    max-height: 40px;
}

.vertical-center {
    top: 17%;
    position: absolute;
}

.border {
        box-shadow: 0 0 5px #ced4da;
}

.logo{
    width:100px;
}


.bg-waywize {
    
    background-color:#172678;
    color: white;
}

.basic-navbar-nav{
    color: white;
}


.globe{
    width: 20px;
    height: 20px;
    fill: #009df0; 
}

.multiselect{
    border-width:0px !important;
    background-color: #f8f9fa !important;
    min-width:210px;
    max-width:500px;
}

.nav-pills .nav-link.active{
    background-color: #f8f9fa !important; 
    color: black !important;
}



.nav-link{
    color:white !important;
}


/* Paras */
.logo {
  height: 32px;
  margin: 16px;
}

.site-layout .site-layout-background {
  background: rgb(204, 45, 45);
  background-color: #172679 !important;
}

[data-theme="dark"] .site-layout .site-layout-background {
  background-color: #172679 !important;
}
/* .site-layout-background {
    background: #fff;
  } */

/* {.nav-links:hover {
    background-color:green; /*will change background-color of element on hover */
/* color: white;  */

/* .slider-body .nav-link{
    color:white;
} */

.slider-body .nav-link {
  color: grey;
}

.slider-body .nav-link ::before {
  color: grey;
}

.icon-picture {
  width: 20px;
  height: 30px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.ant-menu-item-icon:hover {
  color: #05f29b !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title :hover {
  cursor: pointer;
}

/* .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover{
    color: orange !important;
    background-color: orange;
} */

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title ::after {
  background-color: transparent !important;
}

.navbar-heading-small {
  margin-left: 190px;
}

.navbar-heading-large {
  margin-left: 390px;
}

#nav-project-dd {
  background: #ffffff;
  color: #000000;
  font-size: 20px;
  text-decoration: none;
  border: none;
}

#accountNav {
  color: #172679 !important;
}

#accountNav,
#nav-project-dd {
  margin-right: 30px;
  outline: none;
  box-shadow: none;
}

#accountNav::after,
#nav-project-dd::after {
  position: absolute;
  border: none;
  content: url(../../assets/images/caret.svg);
}

/*.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title ::after{
    background-color: transparent !important;
}*/
.ant-menu-item :hover {
  color: #05f29b !important;
}

.ant-menu-item-selected {
  background-color: #05f29b !important;
}

.ant-menu-item-selected:hover {
  background-color: grey !important;
  color: white !important;
}

.ant-layout-sider-trigger:hover {
  color: #05f29b;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #05f29b !important;
  background-color: transparent;
}

.ant-menu-item-icon {
  font-size: 20px !important;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #05f29b !important;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #05f29b !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
}



.fuelsave-sider.fuelsave-sider-dark.fuelsave-sider-has-trigger.slider-body {
  background: #172679;
  z-index: 10;
  top: -97px;
  height: calc(100vh + 97px);
  position: relative;
  min-width: 0;
  transition: all 0.2s;
}

.fuelsave-fixed-sider {
  position: fixed;
  height: 100%;
  background-color: #172679;
}

.fuelsave-fixed-sider.is-open {
  width: 350px;
}

.fuelsave-fixed-sider.is-close {
  width: 139px;
}

.fuelsave-logo-title {
  font-size: 30px;
  border-right: solid #ffffff 1px;
  padding-right: 20px;
  margin-right: 15px;
}

.fuelsave-logo {
  height: 39px;
  width: 60px;
  margin-top: 15px;
}

.fuelsave-sider.fuelsave-sider-dark.fuelsave-sider-has-trigger.slider-body .fuelsave-sider-trigger {
  left: 330px;
}

.fuelsave-sider.fuelsave-sider-dark.fuelsave-sider-collapsed.fuelsave-sider-has-trigger.slider-body .fuelsave-sider-trigger {
  left: 119px;
}

.fuelsave-sider-trigger {
  cursor: pointer;
  top: 33px;
  position: fixed;
  box-shadow: 4px 3px 6px #A7ACBF4F;
  width: 41px !important;
  height: 41px !important;
  color: #000000;
  background: #ffffff;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.fuelsave-sider-collapsed {
  width: 139px !important;
  min-width: 139px !important;
  max-width: 139px !important;
}

.ant-menu.ant-menu-inline > .ant-menu-item {
  padding-left: 48px !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - -8px) !important;
}

li.ant-menu-item {
  display: flex;
  margin: 20px 0 !important;
}

li.ant-menu-item span svg {
  height: 30px;
  width: 30px;
}

.sidebar-copy-right-txt {
  position: absolute;
  bottom: 20px;
  word-break: break-all;
  font-size: 10.5px;
  text-align: center;
  left: 0;
  right: 0;
}