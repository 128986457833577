@import "../../../assets/scss/colors.scss";

.cdr-container {
    background-color: $white1;
    .form-holder {
        color: $black1;
        text-align: left;
        label {
            min-width: 300px;
        }
        .err-msg {
            color: $red1;
            font-size: 15px;
        }
    }
    .export-pdf-btn,
    .submit-fm-btn {
        background: $green1;
        height: 50px;
        cursor: pointer;
        border-radius: 42px;
        border: none;
    }
    .export-pdf-btn {
        width: 200px;
        float: right;
    }
    .submit-fm-btn {
        width: 300px;
        color: $white1;
    }
    .response-holder {
        width: 100%;
        .response-table-holder {
            max-height: 500px;
            overflow-y: scroll;
            position: relative;
            .table-div {
                position: absolute;
                top: 0;
            }
        }
        .response-table-holder,
        .response-graph-holder {
            width: 50%;
        }

        table {
            position: absolute;
            top: 0;
            max-width: 80%;
            border-collapse: separate;
            border: solid 1px $grey4;
            box-shadow: 0px 9px 13px $grey4;
            border-radius: 6px;
            -moz-border-radius: 6px;
        }

        th,
        td {
            border: none;
        }

        td:first-child,
        th:first-child {
            border-left: none;
        }

        .ref-color {
            height: 20px;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            border-radius: 50%;
            margin: 0 auto;
        }
    }
}
