.landxml-number-input-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    .landxml-number-input-label {
        text-transform: capitalize;
        margin: 0;
    }
    input {
        border: solid 1px;
        border-radius: 4px;
        outline: none;
        height: 56px;
        padding: 0 16px;
    }
}