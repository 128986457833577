.forecasttable {
  width: 40vw !important;
  height: 45vh !important;
  font-family: MediumSizeFont;
}

div {
  font-family: MediumSizeFont;
}

td {
  font-family: BookSizeFont;
}

#captionclass {
  font-size: 35;
}

thead {
  font-family: MediumSizeFont;
  background-color: white;
  color: black;
}

tbody {
  font-family: BookSizeFont;
  background-color: white;
  color: black;
}

.MuiAccordionDetails-root {
  display: block !important;
}
