
 :root {
   --font-family-sans-serif: 'Gotham Rounded Light', 'Open Sans', sans-serif;
   /* --primary: #009df0; */
  --primary: rgb(3, 120, 79,0.9);
  --secondary: #172678;
  --success: #3aa551;
  --danger: #c63418;
  --warning: #f09c00;
  /* --info: #006fad; */
  --info: rgb(3, 120, 79,0.9);
  --light: #fff;
  --dark: #000;
}
 body {
   font-family: 'Gotham Rounded Light', 'Open Sans', sans-serif;
    
}
 h1 {
	 font-family: 'Gotham Rounded', 'Open Sans', sans-serif;
}
 button, input {
	 font-family: 'Gotham Rounded Light', 'Open Sans', sans-serif;
}

