.MuiTypography-root {
    margin: 0;
}

.MuiTypography-body2 {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.MuiTypography-body1 {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.MuiTypography-caption {
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.MuiTypography-button {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.MuiTypography-h1 {
    font-size: 6rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.167;
    letter-spacing: -0.01562em;
}

.MuiTypography-h2 {
    font-size: 3.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: -0.00833em;
}

.MuiTypography-h3 {
    font-size: 3rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
}

.MuiTypography-h4 {
    font-size: 2.125rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
}

.MuiTypography-h5 {
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
}

.MuiTypography-h6 {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.MuiTypography-subtitle1 {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
}

.MuiTypography-subtitle2 {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: 0.00714em;
}

.MuiTypography-overline {
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 2.66;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
}

.MuiTypography-srOnly {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
}

.MuiTypography-alignLeft {
    text-align: left;
}

.MuiTypography-alignCenter {
    text-align: center;
}

.MuiTypography-alignRight {
    text-align: right;
}

.MuiTypography-alignJustify {
    text-align: justify;
}

.MuiTypography-noWrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MuiTypography-gutterBottom {
    margin-bottom: 0.35em;
}

.MuiTypography-paragraph {
    margin-bottom: 16px;
}

.MuiTypography-colorInherit {
    color: inherit;
}

.MuiTypography-colorPrimary {
    color: #3f51b5;
}

.MuiTypography-colorSecondary {
    color: #f50057;
}

.MuiTypography-colorTextPrimary {
    color: rgba(0, 0, 0, 0.87);
}

.MuiTypography-colorTextSecondary {
    color: rgba(0, 0, 0, 0.54);
}

.MuiTypography-colorError {
    color: #f44336;
}

.MuiTypography-displayInline {
    display: inline;
}

.MuiTypography-displayBlock {
    display: block;
}

.jss1.jss2 .jss3 {
    transform: scale(1) translateY(-10px);
}

.jss3 {
    top: -34px;
    z-index: 1;
    position: absolute;
    font-size: 0.75rem;
    transform: scale(0);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01071em;
    transform-origin: bottom center;
}

.jss4 {
    width: 32px;
    height: 32px;
    display: flex;
    transform: rotate(-45deg);
    align-items: center;
    border-radius: 50% 50% 50% 0;
    justify-content: center;
    background-color: currentColor;
}

.jss5 {
    color: #fff;
    transform: rotate(45deg);
}

.MuiSlider-root {
    color: #3f51b5;
    width: 100%;
    cursor: pointer;
    height: 2px;
    display: inline-block;
    padding: 13px 0;
    position: relative;
    box-sizing: content-box;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
}

.MuiSlider-root.Mui-disabled {
    color: #bdbdbd;
    cursor: default;
    pointer-events: none;
}

.MuiSlider-root.MuiSlider-vertical {
    width: 2px;
    height: 100%;
    padding: 0 13px;
}

@media (pointer: coarse) {
    .MuiSlider-root {
        padding: 20px 0;
    }
    .MuiSlider-root.MuiSlider-vertical {
        padding: 0 20px;
    }
}

@media print {
    .MuiSlider-root {
        color-adjust: exact;
    }
}

.MuiSlider-colorSecondary {
    color: #f50057;
}

.MuiSlider-marked {
    margin-bottom: 20px;
}

.MuiSlider-marked.MuiSlider-vertical {
    margin-right: 20px;
    margin-bottom: auto;
}

.MuiSlider-rail {
    width: 100%;
    height: 2px;
    display: block;
    opacity: 0.38;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
}

.MuiSlider-vertical .MuiSlider-rail {
    width: 2px;
    height: 100%;
}

.MuiSlider-track {
    height: 2px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
}

.MuiSlider-vertical .MuiSlider-track {
    width: 2px;
}

.MuiSlider-trackFalse .MuiSlider-track {
    display: none;
}

.MuiSlider-trackInverted .MuiSlider-track {
    background-color: rgb(182, 188, 226);
}

.MuiSlider-trackInverted .MuiSlider-rail {
    opacity: 1;
}

.MuiSlider-thumb {
    width: 12px;
    height: 12px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: -5px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -6px;
    border-radius: 50%;
    justify-content: center;
    background-color: currentColor;
}

.MuiSlider-thumb::after {
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    content: "";
    position: absolute;
    border-radius: 50%;
}

.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 14px rgba(63, 81, 181, 0.16);
}

.MuiSlider-thumb.Mui-disabled {
    width: 8px;
    height: 8px;
    margin-top: -3px;
    margin-left: -4px;
}

.MuiSlider-vertical .MuiSlider-thumb {
    margin-left: -5px;
    margin-bottom: -6px;
}

.MuiSlider-vertical .MuiSlider-thumb.Mui-disabled {
    margin-left: -3px;
    margin-bottom: -4px;
}

.MuiSlider-thumb.Mui-disabled:hover {
    box-shadow: none;
}

@media (hover: none) {
    .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
        box-shadow: none;
    }
}

.MuiSlider-thumbColorSecondary.Mui-focusVisible, .MuiSlider-thumbColorSecondary:hover {
    box-shadow: 0px 0px 0px 8px rgba(245, 0, 87, 0.16);
}

.MuiSlider-thumbColorSecondary.MuiSlider-active {
    box-shadow: 0px 0px 0px 14px rgba(245, 0, 87, 0.16);
}

.MuiSlider-valueLabel {
    left: calc(-50% - 4px);
}

.MuiSlider-mark {
    width: 2px;
    height: 2px;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
}

.MuiSlider-markActive {
    opacity: 0.8;
    background-color: #fff;
}

.MuiSlider-markLabel {
    top: 26px;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    font-size: 0.875rem;
    transform: translateX(-50%);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    white-space: nowrap;
    letter-spacing: 0.01071em;
}

.MuiSlider-vertical .MuiSlider-markLabel {
    top: auto;
    left: 26px;
    transform: translateY(50%);
}

@media (pointer: coarse) {
    .MuiSlider-markLabel {
        top: 40px;
    }
    .MuiSlider-vertical .MuiSlider-markLabel {
        left: 31px;
    }
}

.MuiSlider-markLabelActive {
    color: rgba(0, 0, 0, 0.87);
}

/* Chart.js */

/*
 * DOM element rendering detection
 * https://davidwalsh.name/detect-node-insertion
 */

@keyframes chartjs-render-animation {
    from {
        opacity: 0.99;
    }
    to {
        opacity: 1;
    }
}

.chartjs-render-monitor {
    animation: chartjs-render-animation 0.001s;
}

/*
 * DOM element resizing detection
 * https://github.com/marcj/css-element-queries
 */

.chartjs-size-monitor, .chartjs-size-monitor-expand, .chartjs-size-monitor-shrink {
    position: absolute;
    direction: ltr;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
}

.chartjs-size-monitor-expand>div {
    position: absolute;
    width: 1000000px;
    height: 1000000px;
    left: 0;
    top: 0;
}

.chartjs-size-monitor-shrink>div {
    position: absolute;
    width: 200%;
    height: 200%;
    left: 0;
    top: 0;
}