@import '../../../../assets/scss/colors.scss';

.d-table,
.d-thead,
.d-td {
    border: 1px solid $black1;
    border-collapse: collapse;
}

.d-tr, .d-thead {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    input {
        border: none;
        outline: none;
    }
}