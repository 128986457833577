.input{
    width:90px;
}

.directionbutton{
    width:100%;
}

.alternativebutton{
    width:100%; 
}