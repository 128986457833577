@import "../../../assets/scss/colors.scss";

.confirm-alert-popup-container {
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgb(78 78 78 / 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    .confirm-alert-popup-message {
        width: 440px;
        max-width: 650px;
        border-radius: 5px;
        padding: 20px;
        background-color: $white1;
        color: $black1;
        button {
            height: 56px;
            width: 120px;
            background-color: $green1;
            color: $white1;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background-color: $green2;
            }
        }
    }
}
